import { ModalMessageDialogProps } from "global/components/UI/MessageDialogs/ModalMessageDialog";
import { useEffect, useState } from "react";
import ModalController, {
  DialogController,
  DialogListener,
  ModalMessageDialogSetter,
} from "./interface/ModalController";

const nop: Function = () => {};
let modals: Array<ModalMessageDialogProps> = [];
let currentModal: ModalMessageDialogProps | null;
let modalListener: ModalMessageDialogSetter | null;

let dialogListeners: Array<DialogListener> = [];

function broadcastModalProps(modalProps: ModalMessageDialogProps | null) {
  currentModal = modalProps;
  if (modalListener) {
    modalListener(currentModal);
  }
}

function broadcastDialogProps(
  dialogName: string,
  modalProps: ModalMessageDialogProps | null
) {
  const index = dialogListeners.findIndex((d) => d.name === dialogName);
  if (index !== -1) {
    const updatedListeners = [...dialogListeners];
    updatedListeners[index] = {
      ...dialogListeners[index],
      dialog: modalProps,
    };
    dialogListeners = updatedListeners;
    dialogListeners[index].listner(modalProps);
  }
}

export const useModalDialog = (
  dialogName = "global"
): [ModalMessageDialogProps | null, DialogController] => {
  const [modal, setModal] = useState<ModalMessageDialogProps | null>(null);

  useEffect(() => {
    if (
      (dialogName === "global" && modalListener) ||
      (dialogName !== "global" &&
        -1 !== dialogListeners.findIndex((d) => d.name === dialogName))
    ) {
      throw Error("Only one modal listener allowed");
    }
    if (dialogName === "global") {
      modalListener = setModal;
    } else {
      dialogListeners.push({
        name: dialogName,
        dialog: null,
        listner: setModal,
      });
    }

    return () => {
      if (dialogName === "global") {
        modalListener = null;
      } else {
        dialogListeners = dialogListeners.filter((d) => d.name !== dialogName);
      }
    }; // eslint-disable-next-line
  }, [setModal]);

  if (dialogName === "global") {
    return [modal, {} as DialogController];
  }

  const showDialog = (modalProps: ModalMessageDialogProps) => {
    const aModal: ModalMessageDialogProps = {
      ...modalProps,
      onOkClick: modalProps.onOkClick
        ? () => {
            closeModalFN();
            modalProps.onOkClick!();
          }
        : modalProps.onOkClick,
      onAbortClick: modalProps.onAbortClick
        ? () => {
            closeModalFN();
            modalProps.onAbortClick!();
          }
        : modalProps.onAbortClick,
    };
    broadcastDialogProps(dialogName, aModal);
  };

  const closeModalFN: Function = () => {
    broadcastDialogProps(dialogName, null);
  };

  const disableOkActionFN = (value: boolean) => {
    const index = dialogListeners.findIndex((d) => d.name === dialogName);
    if (index === -1) return;
    const dialog = dialogListeners[index].dialog;
    if (dialog && dialog.onOkClick) {
      broadcastDialogProps(dialogName, {
        ...dialog,
        okActionDisabled: value,
      });
    }
  };

  return [
    modal,
    {
      showDialog: showDialog,
      closeDialog: closeModalFN,
      disableOkAction: disableOkActionFN,
    } as DialogController,
  ];
};

const useModalController = (): ModalController => {
  const showModal = (modalProps: ModalMessageDialogProps) => {
    const aModal: ModalMessageDialogProps = {
      ...modalProps,
      onOkClick: modalProps.onOkClick
        ? () => {
            closeModalFN();
            modalProps.onOkClick!();
          }
        : modalProps.onOkClick,
      onAbortClick: modalProps.onAbortClick
        ? () => {
            closeModalFN();
            modalProps.onAbortClick!();
          }
        : modalProps.onAbortClick,
    };
    if (currentModal && modalProps.nonInteractiveMessage) {
      broadcastModalProps({
        ...currentModal,
        nonInteractiveMessage: modalProps.nonInteractiveMessage,
      });
    } else if (currentModal) {
      modals.push(aModal);
    } else {
      broadcastModalProps(aModal);
    }
  };

  const closeModalFN: Function = () => {
    if (
      currentModal &&
      currentModal.nonInteractiveMessage &&
      currentModal.content
    ) {
      broadcastModalProps({
        ...currentModal,
        nonInteractiveMessage: undefined,
      });
    } else {
      const nextModal = modals.shift() ?? null;
      broadcastModalProps(nextModal);
    }
  };

  return {
    showMessageDialog: (
      message: string,
      okAction?: Function,
      title?: string,
      cancelAction?: Function,
      hideOkAction?: boolean,
      okCaption?: string,
      cancelCaption?: string
    ) => {
      showModal({
        message: message,
        title: title,
        onOkClick: okAction ?? (hideOkAction ? undefined : nop),
        onAbortClick: cancelAction,
        okCaption: okCaption,
        abortCaption: cancelCaption,
      });
    },
    showLoadingDialog: (message: string, title?: string) => {
      showModal({
        message: "",
        nonInteractiveMessage: message,
        title: title,
      });
    },
    showError: (message: string, error: Error) => {
      showModal({ message: message, onOkClick: nop });
      console.warn(error);
    },
    closeModal: closeModalFN,
  } as ModalController;
};

export default useModalController;
